import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';

import { ValueAccessorBase } from '@app/core/class';
import { ChangeCategoryDialogComponent } from '../change-category-dialog/change-category-dialog.component';
import { ItemList } from '@app/core/interfaces';
@Component({
  selector: 'app-select-category-change',
  templateUrl: './select-category-change.component.html',
  styleUrls: ['./select-category-change.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCategoryChangeComponent),
      multi: true
    },
  ]
})
export class SelectCategoryChangeComponent extends ValueAccessorBase<any> implements OnInit, OnChanges {
  @Input() items: { id: number | string, name: string, checked?: boolean }[] = [];
  @Input() valueAttr = 'id';
  @Input() labelAttr = 'name';
  @Input() selected: string[] | number[];
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() isEdit: boolean;
  @Input() hasSearch = true;
  @Input() listItems: ItemList[];
  @Input() icon = 'icomoon icomoon_ic_chevron_right';
  @Input() validateCustomFn: (val: string) => boolean;
  @Output() valueChanged = new EventEmitter<any>();
  @Output() valueClicked = new EventEmitter<any>();
  @ViewChild('search', { static: false }) searchEl: ElementRef;
  filter = '';
  customValue = '';
  isChangeCategory = false;
  @Input() opened: boolean;
  @Input() trick = 0;
  @Input() allowCustomValues = false;
  constructor(
    private modalService: NzModalService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.selected !== undefined) {
      this.value = this.selected;
    }

    document.body.addEventListener('click', () => {
      if (this.opened) {
        this.filter = '';
        this.opened = false;
        this.touched();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selected' in changes) {
      this.value = changes.selected.currentValue;
    }
  }

  toggleState(e: MouseEvent): boolean | void {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (this._disabled) {
      return false;
    }
    if (!this.opened) {
      document.body.click();
    }
    this.filter = '';
    this.opened = !this.opened;
    this.touched();
    if (this.opened && this.hasSearch) {
      this.searchEl.nativeElement.focus();
    }

  }

  selectItem(e: MouseEvent, item: { id: string | number; name: string }): void {
    this.opened = false;
    this.value = item.id;
    switch (this.listItems.length > 0) {
      case true:
        const dialogRef = this.modalService.create({
          nzContent: ChangeCategoryDialogComponent,
          nzMaskClosable: false,
          nzData: {
            type: item.name,
          },
          nzStyle: { width: '450px', height: '220px' },
          nzBodyStyle: { width: '450px', height: '100%', padding: '10px' },
        });
        dialogRef.afterClose.subscribe((res: boolean) => {
          switch (res) {
            case true:
              const selectItem = {
                ...item,
                success: true,
              };
              this.valueChanged.emit(selectItem);
              break;
            case false:
              break;
          }
        });
        break;
      case false:
        this.valueChanged.emit(item);
        this.opened = false;
        e.preventDefault();
        e.stopImmediatePropagation();
        break;
    }
  }

  getSelectedItemLabel() {
    if (!this.multiple) {
      return this.getLabelByValue(this.value);
    }
    return this.value.map((i) => {
      return this.getLabelByValue(i);
    });

  }

  getLabelByValue(value: string): string {
    const obj = this.items.find((i) => {
      return i[this.valueAttr] === value;
    });
    if (obj) {
      return obj[this.labelAttr];
    }
    return value;
  }

  searchClicked(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (!this.opened) {
      document.body.click();
    }
    if (!this.opened && !this.multiple) {
      this.opened = true;
    }
  }

  removeItem(i: number, e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.value.splice(i, 1);
    this.valueChanged.emit(this.value);
    if (!this.opened) {
      this.opened = true;
    }
  }

  handleEnter(e: KeyboardEvent): void {
    if (!this.opened) {
      this.opened = true;
    }
    if (e.keyCode === 13) {
      e.preventDefault();

      this.addCustom();
    }
  }

  getPlural(): string {
    const str = this.value.length.toString();
    if (str.indexOf('1') === str.length - 1) {
      return '';
    }
    return 's';
  }

  addCustom(): void {
    if (!this.allowCustomValues || !this.filter) {
      return;
    }

    const val = this.items.find((i) => {
      return i[this.labelAttr].toLowerCase() === this.filter.toLowerCase();
    });
    const duplicate = this.value.find((i) => {
      return i.toLowerCase() === this.filter.toLowerCase();
    });

    if (val || duplicate) {
      return;
    } else {
      if (this.validateCustomFn && !this.validateCustomFn(this.filter)) {
        return;
      }

      const customItem = {};
      customItem[this.valueAttr] = this.filter;
      customItem[this.labelAttr] = this.filter;

      if (!this.multiple) {
        this.value = [customItem[this.valueAttr]];
        this.filter = '';
        this.opened = false;
      } else {
        this.value.push(customItem[this.valueAttr]);
        this.filter = '';
      }
      this.touched();
      this.valueChanged.emit(this.value);
    }
  }
}
