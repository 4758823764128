export const removedRTBFields = [
  'adtype',
  'vast',
  'js',
  'vastConnectionMode',
];

export const checkboxesRTB = [
  'active',
  'isSecure',
  'isBanner',
  'isNative',
  'isPop',
  'isPush',
  'popRespXml',
  'rewarded',
  'isAudio',
  'mobile_web',
  'mobile_app',
  'desktop',
  'ctv',
  'isVideo',
  'enable_postbid',
  'is_iurl_filter',
  'enable_prebid',
  'useTMT',
  'useForensiq',
  'usePMKey',
  'is_pixalate_postbid',
  'useGeoedge',
  'useBcatFilter',
  'has_badv_filter',
  'gzipResponses',
  'has_response_adomain',
  'has_demand_booster',
  'has_maximised_bidprice',
  'use_secure_filter',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
];

export const removedVastFields = [
  'isBanner',
  'isNative',
  'isPop',
  'isPush',
  'popRespXml',
  'rewarded',
  'mobile_web',
  'mobile_app',
  'adtype',
  'defaultBidfloor',
  'js',
  'useBcatFilter',
  'has_badv_filter',
  'has_demand_booster',
  'is_iurl_filter',
];

export const vastCheckboxes = [
  'active',
  'ctv',
  'desktop',
  'gzipResponses',
  'inApp',
  'isAudio',
  'isVideo',
  'isSecure',
  'fixedPrice',
  'has_response_adomain',
  'is_pixalate_postbid',
  'enable_postbid',
  'enable_prebid',
  'mobileWeb',
  'dc_subnet',
  'def_app',
  'domain',
  'hr_app',
  'ifa',
  'ip',
  'ott',
  'ua',
  'useTMT',
  'useForensiq',
  'use_secure_filter',
  'usePMKey',
  'useGeoedge',
  'usePMPrebid',
];

export const preBidCheckboxes = [
  'active',
  'isSecure',
  'isBanner',
  'isNative',
  'isPop',
  'isPush',
  'isAudio',
  'isVideo',
  'popRespXml',
  'rewarded',
  'mobile_web',
  'mobile_app',
  'desktop',
  'ctv',
  'enable_postbid',
  'enable_prebid',
  'useTMT',
  'useForensiq',
  'usePMKey',
  'is_pixalate_postbid',
  'useGeoedge',
  'useBcatFilter',
  'gzipResponses',
  'has_badv_filter',
  'use_secure_filter',
  'has_response_adomain',
  'has_demand_booster',
  'is_iurl_filter',
  'has_maximised_bidprice',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
];

export const removedPreBidFields = [
  'adtype',
  'vast',
  'js',
  'vastConnectionMode',
];

export const jsCheckboxes = [
  'active',
  'isSecure',
  'mobile_web',
  'mobile_app',
  'desktop',
  'fixedPrice',
  'gzipResponses',
  'has_badv_filter',
  'has_response_adomain',
  'has_demand_booster',
  'has_maximised_bidprice',
  'use_secure_filter',
  'useForensiq',
  'useGeoedge',
  'enable_postbid',
  'enable_prebid',
  'usePMKey',
  'useTMT',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
];

export const removedJsFields = [
  'vastConnectionMode',
  'ctv',
  'adtype',
  'vast',
  'defaultBidfloor',
  'apiLink',
  'usePMPrebid',
  'useBcatFilter',
  'isBanner',
  'isNative',
  'isPop',
  'isPush',
  'isAudio',
  'isVideo',
  'popRespXml',
  'rewarded',
  'defaultBidfloor',
  'useBcatFilter',
  'has_badv_filter',
  'jsAdType',
  'adapter_id',
  'is_pixalate_postbid'
];

export const checkboxesForUpdateForm = [
  'active',
  'isSecure',
  'isBanner',
  'isNative',
  'isPop',
  'isPush',
  'popRespXml',
  'rewarded',
  'isAudio',
  'mobile_web',
  'mobile_app',
  'desktop',
  'isForTest',
  'is_gpp_support',
  'ctv',
  'isVideo',
  'fixedPrice',
  'enable_postbid',
  'enable_prebid',
  'useTMT',
  'useForensiq',
  'usePMKey',
  'is_pixalate_postbid',
  'useGeoedge',
  'usePMPrebid',
  'useBcatFilter',
  'has_badv_filter',
  'gzipResponses',
  'has_response_adomain',
  'has_demand_booster',
  'is_iurl_filter',
  'has_maximised_bidprice',
  'use_secure_filter',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
  'active',
  'has_response_adomain',
];

export const rtbDSPCheckboxes = [
  'active',
  'useAdapter',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'is_only_with_consent',
  'sChain',
  'schain_source',
  'is_schain_complete',
  'ipv4_required',
  'ccpa_check',
  'is_pixalate_postbid',
  'has_badv_limit',
  'has_iab_categories_limit',
  'is_omsdk',
  'deviceIdRequired',
  'banner',
  'native',
  'video',
  'rewarded',
  'mobileWeb',
  'inApp',
  'desktop',
  'ctv',
  'audio',
  'pop',
  'push',
  'iabOttCtv',
  'isBlockedCTV',
  'useTCF',
  'useCustomIfa',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'enable_postbid',
  'dc_subnet',
  'is_requests_empty_appname',
  'mraid_impression_pixel',
  'exclude_mraid_response'
];

export const removedRtbDSPFields = [
  'defaultPriceVast',
  'js',
  'selectedSize',
  'selectedPreBidServer',
  'id',
  'vastConnectionMode',
  'adServerId',
];

export const vastDSPCheckboxes = [
  'active',
  'useAdapter',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'is_only_with_consent',
  'sChain',
  'schain_source',
  'is_schain_complete',
  'ipv4_required',
  'ccpa_check',
  'enable_postbid',
  'is_pixalate_postbid',
  'has_badv_limit',
  'has_iab_categories_limit',
  'is_omsdk',
  'mobileWeb',
  'inApp',
  'desktop',
  'ctv',
  'video',
  'audio',
  'deviceIdRequired',
  'iabOttCtv',
  'isBlockedCTV',
  'useTCF',
  'useCustomIfa',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
];

export const removedVastDSPFields = [
  'js',
  'selectedSize',
  'selectedPreBidServer',
  'nurl',
  'id',
  'banner',
  'native',
  'rewarded',
  'pop',
  'push',
  'is_requests_empty_appname',
  'exclude_mraid_response',
  'mraid_impression_pixel'
];

export const preBidDSPCheckboxes = [
  'active',
  'useAdapter',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'is_only_with_consent',
  'sChain',
  'schain_source',
  'is_schain_complete',
  'ipv4_required',
  'ccpa_check',
  'enable_postbid',
  'is_pixalate_postbid',
  'has_badv_limit',
  'has_iab_categories_limit',
  'is_omsdk',
  'banner',
  'native',
  'video',
  'rewarded',
  'pop',
  'push',
  'mobileWeb',
  'inApp',
  'desktop',
  'ctv',
  'deviceIdRequired',
  'iabOttCtv',
  'isBlockedCTV',
  'useTCF',
  'useCustomIfa',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
  'is_requests_empty_appname',
  'exclude_mraid_response',
  'mraid_impression_pixel'
];

export const removedPreBidDSPFields = [
  'defaultPriceVast',
  'js',
  'audio',
  'selectedSize',
  'id',
  'endpoint',
  'adServerId',
];

export const jsDSPCheckboxes = [
  'active',
  'useAdapter',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'is_only_with_consent',
  'sChain',
  'schain_source',
  'is_schain_complete',
  'ipv4_required',
  'ccpa_check',
  'is_pixalate_postbid',
  'has_badv_limit',
  'has_iab_categories_limit',
  'is_omsdk',
  'mobileWeb',
  'inApp',
  'desktop',
  'deviceIdRequired',
  'iabOttCtv',
  'isBlockedCTV',
  'useTCF',
  'useCustomIfa',
  'ip',
  'ifa',
  'enable_postbid',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
];

export const removedJsDSPFields = [
  'isBlockedCTV',
  'banner',
  'native',
  'video',
  'audio',
  'rewarded',
  'pop',
  'push',
  'ctv',
  'isBlockedCTV',
  'pixalate_pb_settings',
  'vastConnectionMode',
  'rtb_type',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'iabOttCtv',
  'pmPrebidSuspiciousIVT',
  'adServerId',
  'is_requests_empty_appname',
  'exclude_mraid_response',
  'mraid_impression_pixel'
];

export const checkboxesForUpdateDSPForm = [
  'active',
  'useAdapter',
  'sChain',
  'schain_source',
  'is_schain_complete',
  'banner',
  'native',
  'video',
  'pop',
  'push',
  'rewarded',
  'audio',
  'mobileWeb',
  'inApp',
  'desktop',
  'ctv',
  'mismatchedIpFilter',
  'mismatchedBundlesFilters',
  'iabOttCtv',
  'isBlockedCTV',
  'useTCF',
  'is_only_with_consent',
  'ccpa_check',
  'deviceIdRequired',
  'useCustomIfa',
  'is_omsdk',
  'ipv4_required',
  'has_badv_limit',
  'has_iab_categories_limit',
  'is_pixalate_postbid',
  'is_requests_empty_appname',
  'ip',
  'ifa',
  'ua',
  'ott',
  'def_app',
  'domain',
  'hr_app',
  'dc_subnet',
  'exclude_mraid_response',
  'mraid_impression_pixel',
];
